var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { nav: "" } },
    [
      _c(
        "v-list-item",
        {
          attrs: { "two-line": "" },
          on: {
            click: function ($event) {
              return _vm.$emit("goToInvite", { value: true })
            },
          },
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v("add"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Invite")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }