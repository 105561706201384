var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.spaceInvitations.length && !_vm.fetchingSpaceInvitations
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center" },
              [
                _vm._l(_vm.availableInvitationTypes, function (invitationType) {
                  return _c(
                    "div",
                    { key: invitationType },
                    [
                      _c("v-checkbox", {
                        staticClass: "mx-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            small: "",
                                            color:
                                              _vm.getMailIconColor(
                                                invitationType
                                              ),
                                          },
                                        },
                                        [_vm._v("mail")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "secondary--text caption text-uppercase",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .invitationAndRequestStatusTypes[
                                                invitationType
                                              ]
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.invitationsSwitchData[invitationType],
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.invitationsSwitchData,
                              invitationType,
                              $$v
                            )
                          },
                          expression: "invitationsSwitchData[invitationType]",
                        },
                      }),
                    ],
                    1
                  )
                }),
                _c("v-spacer"),
                _c("v-text-field", {
                  staticClass: "mr-2",
                  attrs: {
                    autofocus: "",
                    label: "Filter",
                    solo: "",
                    flat: "",
                    "background-color": "grey lighten-4",
                    dense: "",
                    "hide-details": "",
                    clearable: "",
                    "prepend-inner-icon": "mdi-filter",
                  },
                  model: {
                    value: _vm.invitationEmailSearch,
                    callback: function ($$v) {
                      _vm.invitationEmailSearch = $$v
                    },
                    expression: "invitationEmailSearch",
                  },
                }),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$store.dispatch(
                                          "spaceStore/fetchSpaceInvitations",
                                          _vm.$route.params.sid
                                        )
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      897718951
                    ),
                  },
                  [_c("span", [_vm._v("refresh")])]
                ),
              ],
              2
            ),
            _c("v-divider", { staticClass: "mb-5" }),
            _c("v-data-iterator", {
              attrs: { items: _vm.filteredInvitationsList },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._l(props.items, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin",
                                    },
                                    attrs: { inset: "" },
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "space-between",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.getMailIconColor(
                                                  _vm.getInvitationStatus(item)
                                                ),
                                                "x-large": "",
                                              },
                                            },
                                            [_vm._v("mail")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-medium secondary--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.requestee_email
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    item.notification_timestamp !==
                                                    null
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "caption mt-1 mb-0",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Sent on"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item.notification_timestamp
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.getInvitationStatus(
                                                      item
                                                    ) ===
                                                      _vm
                                                        .invitationAndRequestStatusTypes
                                                        .ACCEPTED &&
                                                    _vm.invitationOrRequestHasTimestamp(
                                                      item
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mt-1 mb-0 caption",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Accepted on"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item
                                                                      .invitation_responses[0]
                                                                      .timestamp
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm.getInvitationStatus(
                                                          item
                                                        ) ===
                                                          _vm
                                                            .invitationAndRequestStatusTypes
                                                            .REJECTED &&
                                                        _vm.invitationOrRequestHasTimestamp(
                                                          item
                                                        )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mt-1 mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Rejected on " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item
                                                                      .invitation_responses[0]
                                                                      .timestamp
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                _vm._l(
                                                  item.roles,
                                                  function (role, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { left: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      role.role_type !==
                                                                      _vm
                                                                        .invitationAndRequestRoleTypes
                                                                        .ORG_ROLE
                                                                        ? _c(
                                                                            "div",
                                                                            _vm._g(
                                                                              {},
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "ComponentWithStyledBorder",
                                                                                [
                                                                                  role.role_type ===
                                                                                  _vm
                                                                                    .invitationAndRequestRoleTypes
                                                                                    .INSTANCE_ROLE
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex align-center",
                                                                                        },
                                                                                        [
                                                                                          role.Name ===
                                                                                          "N/A"
                                                                                            ? _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-1 ml-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "help_outline"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : role.Name ===
                                                                                              _vm
                                                                                                .roleTypes
                                                                                                .INSTANCE_VIEWER
                                                                                            ? _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-1 ml-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " visibility "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : role.Name ===
                                                                                              _vm
                                                                                                .roleTypes
                                                                                                .INSTANCE_EDITOR
                                                                                            ? _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-1 ml-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "edit"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          role.Instance
                                                                                            ? _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      role.Instance
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Target deleted"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : role.role_type ===
                                                                                      _vm
                                                                                        .invitationAndRequestRoleTypes
                                                                                        .SPACE_ROLE
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex align-center",
                                                                                        },
                                                                                        [
                                                                                          role.Name ===
                                                                                          "N/A"
                                                                                            ? _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-1 ml-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "help_outline"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-1 ml-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "security"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                role.Space
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            role.Name === "N/A"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "The target object has been deleted"
                                                                  ),
                                                                ])
                                                              : role.method ===
                                                                _vm
                                                                  .roleUpdateOptions
                                                                  .OVERWRITE_CURRENT_ROLE
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " This role will overwrite the current user role, even if the current role is more powerful that this one. "
                                                                  ),
                                                                ])
                                                              : role.method ===
                                                                _vm
                                                                  .roleUpdateOptions
                                                                  .OVERWRITE_CURRENT_ROLE_IF_LOWER
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " This role will overwrite the current role if the current role is less powerful than this one. "
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }, [
                                        _vm.getInvitationStatus(item) ===
                                          _vm.invitationAndRequestStatusTypes
                                            .PENDING ||
                                        _vm.getInvitationStatus(item) ===
                                          _vm.invitationAndRequestStatusTypes
                                            .CREATED
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "InvitationActionsDialog",
                                                      {
                                                        attrs: {
                                                          successFetchAction:
                                                            () => {
                                                              _vm.$store.dispatch(
                                                                "spaceStore/fetchSpaceInvitations",
                                                                _vm.$route
                                                                  .params.sid
                                                              )
                                                            },
                                                          invitationInfo: item,
                                                          actionType:
                                                            _vm.actionTypes
                                                              .RESEND_INVITATION,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("copy-to-clipboard", {
                                                      attrs: {
                                                        textToCopy: item.link,
                                                        buttonClass:
                                                          "caption font-weight-bold",
                                                        buttonColor:
                                                          "secondary",
                                                        buttonName:
                                                          "copy to clipboard",
                                                        iconButton: true,
                                                        isSmall: true,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "InvitationActionsDialog",
                                                      {
                                                        attrs: {
                                                          successFetchAction:
                                                            () => {
                                                              _vm.$store.dispatch(
                                                                "spaceStore/fetchSpaceInvitations",
                                                                _vm.$route
                                                                  .params.sid
                                                              )
                                                            },
                                                          invitationInfo: item,
                                                          actionType:
                                                            _vm.actionTypes
                                                              .REVOKE_INVITATION,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _c("invite-btn", {
                          on: {
                            goToInvite: function ($event) {
                              return _vm.$emit("goToInvite", { value: true })
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "no-results",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-3",
                            attrs: {
                              value: true,
                              color: "error",
                              icon: "warning",
                              text: "",
                            },
                          },
                          [_vm._v("Your search for found no results.")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                538387397
              ),
            }),
          ],
          1
        )
      : !_vm.spaceInvitations.length && !_vm.fetchingSpaceInvitations
      ? _c(
          "div",
          [
            _c(
              "v-alert",
              { attrs: { text: "", prominent: "", type: "info" } },
              [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("No invitations found"),
                  ]),
                  _c(
                    "span",
                    [
                      _vm._v(
                        " Currently this space has no invitation history. You can "
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            dark: "",
                            color: "info",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("goToInvite", { value: true })
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("add"),
                          ]),
                          _vm._v(" Invite "),
                        ],
                        1
                      ),
                      _vm._v(" new members. "),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm.fetchingSpaceInvitations
      ? _c(
          "div",
          _vm._l(
            [
              { id: "c1", opacity: 1 },
              { id: "c2", opacity: 0.75 },
              { id: "c3", opacity: 0.5 },
            ],
            function (item) {
              return _c("div", { key: item.id, staticClass: "my-6" }, [
                _c(
                  "div",
                  { style: { opacity: item.opacity } },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: { type: "list-item-avatar-two-line" },
                    }),
                  ],
                  1
                ),
              ])
            }
          ),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }